import { StyleSheet } from "react-native";

export default StyleSheet.create({
  openViewMessage: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  openViewButton: {
    flex: 1,
    alignItems: "center",
  },
  openButton: {
    borderWidth: 14,
    width: 240,
    height: 240,
    borderRadius: 120,
    borderColor: "rgba(39,114,245,0.2)",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#034ED2",
  },
  openButtonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 24,
  },

  addicionalButton: {
    marginTop: 10,
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 8,
    borderColor: "rgba(39,114,245,0.2)",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#034ED2",
  },
  addicionalButtonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 24,
  },
});
