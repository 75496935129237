import React, { useEffect } from "react";
import { View, ActivityIndicator } from "react-native";
import { isAuthenticated, logout } from "../../services/auth";

export default function AuthLoadingScreen({ navigation }) {
  useEffect(() => {
    if (isAuthenticated()) {
      navigation.replace("OpenGateScreen");
    } else {
      logout({ navigation });
      navigation.replace("LoginScreen");
    }
  }, []);

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <ActivityIndicator size="large" color="#034ED2" />
    </View>
  );
}

AuthLoadingScreen.navigationOptions = () => {
  return {
    header: null,
  };
};
