const intitialState = {
    userBiometry: [],
};

export default function auth(state = intitialState, action) {
    switch (action.type) {
        case "SET_USER_BIOMETRY":
            return { ...state, userBiometry: action.payload };
        default:
            return state;
    }
}
