import React from 'react';
import { Alert, Image, Text, TouchableOpacity, View } from 'react-native';
import styles from './header.style';
import logo from "../../../assets/images/app_icon/ico-megagestao-app-android.png";
import { Ionicons } from '@expo/vector-icons';

export default function HeaderComponent() {
    return (
        <View style={styles.headerView}>
            <View
                style={
                    styles.headerLogoView
                }>
                <Image
                    source={logo}
                    style={
                        styles.headerLogo
                    }>
                </Image>
            </View>
            <Text
                style={
                    styles.headerText
                }>
                MegaAcesso
            </Text>
        </View>
    );

}