import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    headerView: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerLogoView: {
        backgroundColor: '#FFF',
        padding: 5,
        borderRadius: 100
    },  
    headerLogo: {
        height: 35,
        width: 35
    },
    headerText: {
        fontSize: 25,
        marginLeft: 10,
        color: '#FFF'
    }
});