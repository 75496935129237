import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    logoView: {
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 100,
        shadowOpacity: 0.75,
        shadowRadius: 5,
        shadowColor: '#000',
        shadowOffset: {
            height: 2,
            width: 2
        },
        elevation: 10,
        width: 150,
        height: 150
    },
    logoImage: {
        width: 100,
        height: 100
    },
    textLogo: {
        color: '#fff',
        fontSize: 22,
        marginTop: 10,
        marginBottom: 20
    },
    formView: {
        width: '75%',
        height: 180,
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#fff',
        padding:16,
        borderRadius: 10,
        shadowOpacity: 0.75,
        shadowRadius: 5,
        shadowColor: '#000',
        shadowOffset: {
            height: 2,
            width: 2
        },
        elevation: 10
    },
    inputView: {
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: 99
    },
    textInput: {
        borderBottomColor: '#034ED2',
        borderBottomWidth: 1.5,
        marginBottom: 10,
        width: '100%',
        fontSize: 18,
        color: '#145AD2',
        padding: 6,
        paddingLeft: 35
    },
    button: {
        backgroundColor: '#034ED2',
        color: '#FFF',
        padding: 2,
        borderWidth: 1,
        borderRadius: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});
