const intitialState = {
    user: [],
};

export default function auth(state = intitialState, action) {
    switch (action.type) {
        case "SET_USER":
            return { ...state, user: action.payload };
        case "LOGOUT":
            return { ...state, user: [] };
        default:
            return state;
    }
}
