import React from "react";
import * as Sentry from "sentry-expo";

import { Provider } from "react-redux";
import { store, persistor } from "./src/store/store";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./src/routes";

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

Sentry.init({
  dsn: "https://glet_6b94a7c97162c070c59732bb998c02ef@gitlab.cloudmega.com.br/api/v4/error_tracking/collector/220",
  enableInExpoDevelopment: true,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();