import React, { useEffect } from "react";
import {
    Platform,
    Text,
    KeyboardAvoidingView,
    View,
    Image,
    TextInput,
    SafeAreaView,
    TouchableOpacity,
    Alert,
    Keyboard,
} from "react-native";
import { Button } from "react-native-paper";
import { LinearGradient } from "expo-linear-gradient";
import Svg, { G, Path } from "react-native-svg";
import logo from "../../../assets/images/app_icon/ico-megagestao-app-android.png";
import styles from "./login.style";
import { Ionicons } from "@expo/vector-icons";
import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";
import allActions from "../../store/actions/index";
import { useDispatch } from "react-redux";
import { store } from "../../store/store";
import * as LocalAuthentication from "expo-local-authentication";

export default function LoginScreen({ navigation }) {
    const [usuario, setUsuario] = React.useState(null);
    const [senha, setSenha] = React.useState(null);
    const [showPassword, setShowPassword] = React.useState(true);
    const [senhaBio, setSenhaBio] = React.useState(null);
    const textInput = {};
    const dispatch = useDispatch();

    useEffect(() => {
        if (usuario && senhaBio) {
            logarComBiometria();
        } else {
            const state = store.getState();
            const userBiometry = state.authBiometry.userBiometry;
            if (userBiometry) {
                setUsuario(userBiometry.userBio);
                setSenhaBio(userBiometry.senhaBio);
            }
        }
    }, [senhaBio]);

    function alerta(titulo, mensagem) {
        if (Platform.OS == 'web') {
            window.alert(mensagem)
        } else {
            Alert.alert(titulo, mensagem)
        }
    }

    function logar() {
        Keyboard.dismiss();
        if (!usuario || !senha) {
            alerta("Atenção", "Preencha todos os campos para continuar!");
        } else {
            trackPromise(
                api.post(`/auth`, {
                    username: usuario,
                    userPassword: senha,
                }).then((res) => {
                    const user = res.data;
                    dispatch(allActions().authActions.setUser(user));
                    dispatch(allActions().authBiometryActions.setUserBiometry({ userBio: usuario, senhaBio: senha }));
                    navigation.replace("OpenGateScreen");
                }).catch((error) => {
                    alerta("Erro", `Dados incorretos!\n${error}`);
                })
            );
        }
    }


    async function logarComBiometria() {
        if (senhaBio) {
            const compatible = await LocalAuthentication.hasHardwareAsync();
            if (compatible) {
                let biometricRecords = await LocalAuthentication.isEnrolledAsync();
                if (biometricRecords) {
                    const result = await LocalAuthentication.authenticateAsync({
                        promptMessage: 'Logar usando Biometria',
                        cancelLabel: 'Usar a senha',
                        disableDeviceFallback: true,
                    });
                    if (result.success) {
                        trackPromise(
                            api.post(`/auth`, {
                                username: usuario,
                                userPassword: senhaBio,
                            }).then((res) => {
                                const user = res.data;
                                dispatch(allActions().authActions.setUser(user));
                                navigation.replace("OpenGateScreen");
                            }).catch((error) => {
                                alerta("Erro", `Dados incorretos!\n${error}`);
                                dispatch(allActions().authBiometryActions.setUserBiometry([]));
                                setSenhaBio(null);
                            })
                        );
                    } else {
                        textInput.senha.focus();
                    }
                }
            }
        }
    }

    return (
        <LinearGradient
            colors={["#145AD2", "#034ED2"]}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 1 }}
            style={{
                flex: 1,
                width: "100%",
                height: "100%",
            }}
        >
            <Svg
                width="208"
                height="224"
                viewBox="0 0 208 224"
                style={{
                    position: "absolute",
                    zIndex: 0,
                    top: 0,
                    left: 0,
                }}
            >
                <G
                    fill="none"
                    fillRule="evenodd"
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    opacity="0.2"
                    style={{
                        mixBlendMode: "soft-light",
                    }}
                >
                    <Path d="M129-109V1.953l-.075.047L54-53.488z"></Path>
                    <Path d="M130 1v111L54 56.512v-.048z"></Path>
                    <Path d="M130 111.512v.084L54.094 167l-.094-.072V56z"></Path>
                    <Path d="M129.856 111.58v110.376l-75.818-55.193zm75.922-55.42v.168l-75.865 55.217-.057-.049V.894zM129 1.488L54 57V-53.964l.057-.036z"></Path>
                    <Path d="M129-109V1.953l-.075.047L54-53.488z"></Path>
                    <Path d="M55-53.488v.084L-20.906 2-21 1.928V-109zm-.209 219.984l-75.884 55.459V111.097l.057-.036z"></Path>
                    <Path d="M54 57v110.953l-.075.047L-21 112.512z"></Path>
                    <Path d="M55 56.44v.168L-20.942 112l-.058-.048V1z"></Path>
                    <Path d="M55-53.488v.084L-20.906 2-21 1.928V-109z"></Path>
                    <Path d="M54-54V57L-21 1.494zm75 55.488L54 57V-53.964l.057-.036z"></Path>
                </G>
            </Svg>
            <Svg
                style={{
                    position: "absolute",
                    zIndex: 0,
                    bottom: 0,
                    right: 0,
                }}
                width="323"
                height="431"
                viewBox="0 0 323 431"
            >
                <G
                    fill="none"
                    fillRule="evenodd"
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    opacity="0.2"
                    style={{
                        mixBlendMode: "soft-light",
                    }}
                >
                    <Path d="M376 390v132l-90-65.986v-.057zM285 455.929v.2L195.068 522l-.068-.058V390zM285 192v132l-90-65.986v-.057z"></Path>
                    <Path d="M285 323.014v.1L195.111 389l-.111-.085V257zM285 324v132l-90-66.007zM376 389.986L286 456V324.042l.068-.042zM376 257v131.942l-.09.058L286 323.013zM344-13v37.983l-.026.017L318 6.004zM376 125.928v.2L286.068 192l-.068-.058V60z"></Path>
                    <Path d="M376 390v132l-90-65.986v-.057zM285 455.929v.2L195.068 522l-.068-.058V390zM195 390v132l-90-65.986v-.057z"></Path>
                    <Path d="M285 455.929v.2L195.068 522l-.068-.058V390zM104 192v132l-90-65.986v-.057zM195 389.986L105 456V324.042l.068-.042zM45 390.993L2 422v-61.98l.031-.02zM42 202.496L14 224v-42.985l.021-.015zM195 257v131.942l-.09.058L105 323.013z"></Path>
                    <Path d="M195 257.929v.2L105.068 324l-.068-.058V192zM195 390v132l-90-65.986v-.057zM285 455.929v.2L195.068 522l-.068-.058V390z"></Path>
                </G>
            </Svg>
            <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : "height"}
                style={{
                    flex: 1,
                }}
            >
                {/* LOGO */}
                <View
                    style={{
                        flex: 1,
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <View style={styles.logoView}>
                        <Image source={logo} style={styles.logoImage}></Image>
                    </View>
                    <Text style={styles.textLogo}>MegaAcesso</Text>
                </View>
                {/* FORMULARIO */}
                <SafeAreaView
                    style={{
                        flex: 1,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <View style={styles.formView}>
                        <View style={styles.inputView}>
                            <Ionicons
                                name="person-outline"
                                size={24}
                                color="#034ED2"
                                style={{
                                    position: "absolute",
                                    left: 5,
                                    top: 8,
                                }}
                            />
                            <TextInput
                                placeholder="Usuário"
                                label="Usuário"
                                style={styles.textInput}
                                returnKeyType="next"
                                onChangeText={setUsuario}
                                value={usuario}
                                ref={(input) => {
                                    textInput.usuario = input;
                                }}
                                onSubmitEditing={() => {
                                    textInput.senha.focus();
                                }}
                                blurOnSubmit={false}
                                autoCapitalize="none">
                            </TextInput>
                        </View>
                        <View style={styles.inputView}>
                            <Ionicons
                                name="key-outline"
                                size={24}
                                color="#034ED2"
                                style={{
                                    position: "absolute",
                                    left: 5,
                                    top: 8,
                                }}
                            />
                            <TextInput
                                placeholder="Senha"
                                label="Senha"
                                style={styles.textInput}
                                secureTextEntry={showPassword}
                                onChangeText={setSenha}
                                value={senha}
                                ref={(input) => {
                                    textInput.senha = input;
                                }}
                                onSubmitEditing={() => {
                                    logar();
                                }}
                                autoCapitalize="none">
                            </TextInput>
                            <TouchableOpacity
                                hitSlop={{
                                    top: 20,
                                    bottom: 20,
                                    left: 20,
                                    right: 20,
                                }}
                                style={{
                                    position: "absolute",
                                    right: 5,
                                    top: 8,
                                }}
                                onPress={() => setShowPassword(!showPassword)}
                            >
                                <Ionicons
                                    name={showPassword ? "eye-outline" : "eye-off-outline"}
                                    size={24}
                                    color="#034ED2"
                                />
                            </TouchableOpacity>
                        </View>
                        <View
                            style={{
                                flexDirection: "row",
                                width: '100%',
                                alignItems: "center"
                            }}>
                            {senhaBio && <TouchableOpacity
                                hitSlop={{
                                    top: 20,
                                    right: 20,
                                    bottom: 20,
                                    left: 20,
                                }}
                                style={{
                                    position: 'absolute',
                                    marginLeft: '5%'
                                }}
                                onPress={() => logarComBiometria()}
                            >
                                <Ionicons name="ios-finger-print-sharp" color="#034ED2" size={30} />
                            </TouchableOpacity>}
                            <Button
                                iconAlign="right"
                                icon="login"
                                mode="contained"
                                onPress={logar}
                                style={styles.button}
                            >
                                Logar
                            </Button>
                        </View>
                    </View>
                </SafeAreaView>
            </KeyboardAvoidingView>
        </LinearGradient>
    );
}
