import React, { useEffect, useContext, useState } from "react";
import { Alert, Platform, Text, TouchableOpacity, View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import styles from "./open-gate.style";
import { logout, getSocket } from "../../services/auth";

import * as Location from "expo-location";
import Geolocation from "@react-native-community/geolocation";

import { useSelector } from "react-redux";

import io from "socket.io-client";
import { URL } from "../../configs/configs";
import { platformApiLevel } from "expo-device";

export default function OpenGateScreen({ navigation }) {
  const usuario = useSelector((state) => state.auth.user);
  const { token } = useSelector((state) => state.auth.user);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketio = io.connect(URL, {
      query: { token },
    });
    setSocket(socketio);
    return () => {
      socketio.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      // Escutando o evento de alerta
      socket.on("alerta", (alerta) => {
        if (alerta) {
          if (Platform.OS == "web") {
            window.alert(alerta.message);
          } else {
            Alert.alert(alerta.title, alerta.message);
          }
        }
      });
      socket.on("connect_error", () => {
        if (Platform.OS == "web") {
          window.alert("Não foi possível conectar ao servidor de socket");
        } else {
          Alert.alert(
            "Erro",
            "Não foi possível conectar ao servidor de socket"
          );
        }
      });
    }
  }, [socket]);

  useEffect(async () => {
    // Location.watchPositionAsync(
    //   {
    //     accuracy: Location.Accuracy.Highest,
    //     timeInterval: 300,
    //     distanceInterval: 1,
    //   },
    //   (loc) => {
    //     calcuarDistancia(loc.coords);
    //   }
    // ).then();
  }, []);

  const [distancia, setDistancia] = useState(null);
  const [status, setStatus] = useState(null);

  function calcuarDistancia(coords) {
    let latitudeMega = -16.632889;
    let longitudeMega = -49.2295;

    let distanciaAtual =
      12742000 *
      Math.asin(
        Math.sqrt(
          Math.pow(
            Math.sin(((latitudeMega - coords.latitude) * Math.PI) / 180 / 2),
            2
          ) +
            Math.cos((latitudeMega * Math.PI) / 180) *
              Math.cos((coords.latitude * Math.PI) / 180) *
              Math.pow(
                Math.sin(
                  ((longitudeMega - coords.longitude) * Math.PI) / 180 / 2
                ),
                2
              )
        )
      );
    // if (
    //   distanciaAtual.toFixed(0) !== distancia.toFixed(0) &&
    //   distanciaAtual !== undefined
    // ) {
    // }
    setDistancia(distanciaAtual.toFixed(0));
  }

  async function ativarLocalizacao() {
    // setInterval(() => {
    //   Location.getCurrentPositionAsync()
    //     .then((loc) => {
    //       console.log("coords", loc.coords);
    //       calcuarDistancia(loc.coords);
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // }, 1000);

    Platform.OS === "web"
      ? Geolocation.watchPosition((loc) => {
          calcuarDistancia(loc.coords);
        })
      : Location.watchPositionAsync(
          {
            accuracy: Location.Accuracy.Highest,
            timeInterval: 300,
            distanceInterval: 1,
          },
          (loc) => {
            calcuarDistancia(loc.coords);
          }
        ).then();
  }

  async function permitirLocalizacao() {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status === "granted") {
      setStatus(status);
      ativarLocalizacao();
    } else {
    }
  }

  async function abrirFecharPortao(user) {
    let dados = { user, distancia };
    socket.emit("abrefecha_mobile", dados);
  }
  function reiniciaArduino() {
    socket.emit("solicita_reinicializacao_arduino");
  }
  function atualizaArduino() {
    socket.emit("solicita_atualizacao_arduino");
  }
  useEffect(() => {
    Location.getForegroundPermissionsAsync().then(function (response) {
      setStatus(response.status);
      if (status === "granted") {
        ativarLocalizacao();
      }
    });
  }, [status]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          hitSlop={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          onPress={() => logout({ navigation })}
        >
          <Ionicons name="exit-outline" color="#FFF" size={30} />
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View style={styles.openViewMessage}>
        <Text
          style={{
            fontSize: 25,
          }}
        >
          Bem vindo a Megasoft
        </Text>
        <Text
          style={{
            fontSize: 20,
          }}
        >
          {usuario?.displayName}
        </Text>
        <Text>
          {status !== "granted"
            ? "Aplicativo sem permissão para localização"
            : "Distancia: " + distancia + " Metros"}
        </Text>
      </View>
      <View style={styles.openViewButton}>
        {/* <Button
                    mode="contained"
                    icon="door-closed"
                    onPress={fecharPortao}
                    style={[
                        styles.openButton,
                        {
                            backgroundColor: '#777'
                        }
                    ]}>
                    Fechar
                </Button> */}
        {status !== "granted" ? (
          <TouchableOpacity
            onPress={() => permitirLocalizacao()}
            style={styles.openButton}
          >
            <Text style={styles.openButtonText}>Ativar Localização</Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={() => abrirFecharPortao(usuario)}
            style={styles.openButton}
          >
            <Text style={styles.openButtonText}>Abrir/Fechar</Text>
          </TouchableOpacity>
        )}
        {usuario.sAMAccountName === "luizjr" && (
          <>
            <TouchableOpacity
              onPress={() => atualizaArduino(usuario)}
              style={styles.addicionalButton}
            >
              <Text style={styles.openButtonText}>Atualiza Arduino</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => reiniciaArduino(usuario)}
              style={styles.addicionalButton}
            >
              <Text style={styles.openButtonText}>Reinicia Arduino</Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    </View>
  );
}
