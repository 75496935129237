import React from "react";
import { StyleSheet } from "react-native";
import { StatusBar } from "expo-status-bar";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AuthLoadingScreen from "../../src/screens/AuthLoadingScreen";
import LoginScreen from "../../src/screens/LoginScreen/login.screen";
import OpenGateScreen from "../../src/screens/OpenGateScreen/open-gate.screen";
import HeaderComponent from "../../src/components/header/header.component";

const Stack = createNativeStackNavigator();
export default function Routes() {
  return (
    <NavigationContainer style={styles.container}>
      <StatusBar backgroundColor="#c8c8c8" barStyle="dark-content" />
      <Stack.Navigator initialRouteName="AuthLoadingScreen">
        <Stack.Screen
          name="AuthLoadingScreen"
          component={AuthLoadingScreen}
          options={{
            headerShown: false,
          }}
        ></Stack.Screen>
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={{
            headerShown: false,
          }}
        ></Stack.Screen>
        <Stack.Screen
          name="OpenGateScreen"
          component={OpenGateScreen}
          options={{
            headerTintColor: "#FFF",
            headerStyle: {
              backgroundColor: "#034ED2",
            },
            headerTitle: (props) => <HeaderComponent />,
            headerBackVisible: false,
          }}
        ></Stack.Screen>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
});
