import jwt from "expo-jwt";
import { jwtSecret } from "../configs/configs";

import { store } from "../store/store";
import allActions from "../store/actions/index";

export function isAuthenticated() {
    const state = store.getState();
    const { token } = state.auth.user;
    if (token) {
        try {
            jwt.decode(token, jwtSecret);
            return true;
        } catch (error) {
            return false;
        }
    }
    return false;
}

export async function logout({ navigation }) {
    store.dispatch(allActions().authActions.logout());
    navigation.replace("LoginScreen");
}
