import appJson from "../../app.json";
import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

import authReducers from "./reducers/authReducers";
import authBiometryReducers from "./reducers/authBiometryReducers";

const authPersistConfig = {
  key: "auth",
  version: appJson.expo.version,
  storage: AsyncStorage,
};

const authBiometryPersistConfig = {
  key: "authBiometry",
  version: appJson.expo.version,
  storage: AsyncStorage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducers),
  authBiometry: persistReducer(authBiometryPersistConfig, authBiometryReducers),
});

const store = createStore(rootReducer);
const persistor = persistStore(store);

export { store, persistor };
