import axios from "axios";
import { URL } from "../configs/configs";
import { logout } from "./auth";
import { store } from "../store/store";

const api = axios.create({ baseURL: URL });

api.interceptors.request.use(async (config) => {
  const state = store.getState();
  const { token } = state.auth.user;

  config.headers["Content-Type"] = "application/json";
  config.headers["Accept"] = "application/json";
  // config.headers["Access-Control-Allow-Origin"] = "*";
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    if (error.response?.status === 401) {
      logout();
    }

    return Promise.reject(error);
  }
);

export default api;
